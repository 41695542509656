.p-listbox {
  .p-listbox-list {
    .p-listbox-item {
      @apply
        tw-p-16-px
        tw-bg-white
        tw-rounded-8-px
        tw-my-8-px
        tw-mx-auto
        tw-border-0.5-px
        tw-border-lavender
        tw-border-solid
        tw-min-h-88-px
        hover:tw-drop-shadow-switch-org-item
        focus:tw-shadow-none;

      &.p-highlight {
        @apply
          tw-text-initial
          tw-bg-white
      }
    }

    .p-listbox-empty-message {
      @apply
        tw-p-0
    }

  }

  &:not(.p-disabled) {
    .p-listbox-item {
      &:not(.p-highlight) {
        &:not(.p-disabled) {
          @apply
            hover:tw-bg-white
        }
      }
    }
  }
}
