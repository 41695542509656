.p-tooltip {
  @apply
    tw-mt-12-px
    tw-animate-tooltip;

  .p-tooltip-text {
    @apply
      tw-bg-text-color-3
      tw-text-white
      tw-leading-1.4
      tw-text-12-px
      tw-py-4-px
      tw-px-8-px
      tw-h-auto
      tw-break-words
      tw-whitespace-normal
      tw-overflow-visible
      tw-font-500
  }

  &.p-tooltip-bottom {
    .p-tooltip-arrow {
      @apply
        tw-border-b-text-color-3;
    }
  }
}

.p-tooltip-bottom {
  .p-tooltip-arrow {
    @apply
      -tw-top-4-px
      tw-absolute
      tw-overflow-visible
      tw-border-b-12-px
      tw-border-x-8-px
      -tw-ml-6-px;
  }
}
