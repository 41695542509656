.p-checkbox {

  .p-checkbox-box {
    @apply
      tw-border-input;
    
    &.p-highlight {
      @apply
        tw-border-primary
        tw-bg-primary
    }
  }

  &:not(.p-checkbox-disabled) {
    .p-checkbox-box {
      @apply
        hover:tw-border-input;

      &.p-focus {
        @apply
          tw-shadow-none
          tw-border-input
      }

      &.p-highlight {
        @apply
          hover:tw-border-primary
          hover:tw-bg-primary
      }
    }
  }

}