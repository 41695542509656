.p-toast {
  @apply
    tw-opacity-100;

  &.p-toast-top-right {
    @apply
      tw-w-auto;
  }

  .p-toast-message {
    @apply
      tw-rounded-8-px
      tw-border-0
      tw-m-0
      tw-text-14-px
      tw-font-500;

    &.p-toast-message-success {
      @apply
        tw-bg-sea-green
    }

    &.p-toast-message-error {
      @apply
        tw-bg-fire-engine-red
    }
    
    &.p-toast-message-info {
      @apply
        tw-bg-cornflower-blue
    }
    .p-toast-message-content {
      @apply
        tw-text-white
        tw-py-12-px
        tw-pl-12-px
        tw-pr-20-px
        tw-justify-between;
      .p-toast-detail {
        @apply
          tw-m-0
      }
    }

    .p-toast-icon-close {
      @apply
        tw-hidden
    }
  }

  &.p-toast-bottom-center {
    @apply
      tw-bottom-40-px
      tw-max-w-650-px
      tw-min-w-320-px
      tw-w-max
  }
}
