
.input-text {
  @apply
    tw-border
    tw-border-input
    tw-border-solid
}

.error-space {
  @apply
    tw-min-h-24-px
    tw-float-right
    tw-min-w-px
}

.input-label {
  @apply 
    tw-text-14-px 
    tw-text-text-color-1 
    tw-pt-8-px;
}

.p-inputtext {
  @apply
    enabled:focus:tw-shadow-none
    tw-border-quartz
}

.error {
  @apply 
    tw-text-fire-engine-red 
    tw-pt-2-px 
    tw-pr-6-px 
    tw-pl-0 
    tw-text-12-px;
}
