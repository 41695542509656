.p-dropdown-panel {

  .p-dropdown-items {

    .p-dropdown-item {
      
      @apply
        tw-border-b
        tw-border-solid
        tw-border-quartz
        tw-min-h-32-px
        tw-h-auto
        tw-break-words
        tw-py-8-px
        tw-px-32-px
        tw-leading-1.4
        tw-mx-8-px;

      &.p-highlight {
        @apply
          tw-text-comet;
        background-color: rgba(158,158,158,0.18)
      }
    }
  }
}

.p-dropdown {
  @apply
    tw-h-56-px
    tw-flex
    tw-items-center
    tw-pl-24-px
    tw-border-quartz;

  &:not(.p-disabled) {

    @apply
      hover:tw-border-quartz;

    &.p-focus {
      @apply
        tw-shadow-none
        tw-border-navy-blue
    }
  }
}

p-dropdown {

  &.ng-touched {
    &.ng-invalid {
      .p-dropdown {
        @apply
          tw-border-fire-engine-red
      }
    }
  }
}

.p-dropdown-items-wrapper {
  @apply
    tw-pb-12-px
}