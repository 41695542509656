@mixin spinner($height, $width) {

  &.p-progress-spinner {
    @apply
      tw-h-#{$height}
      tw-w-#{$width};
  }

  .p-progress-spinner-circle {
    @apply
      tw-animate-progress-spinner
  }
}

.spinner-50 {
  @include spinner('50-px', '50-px');
}

.spinner-14 {
  @include spinner('14-px', '14-px');
}